import { useEffect } from "react";
import { getScrollParent } from "../utils/getScrollParent.js";
function useScrollToBottomAction(container, callback) {
    let offset = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0;
    useEffect(()=>{
        if (!container) {
            return undefined;
        }
        const scrollContainer = getScrollParent(container.current);
        if (!scrollContainer) {
            return undefined;
        }
        const handleScroll = ()=>{
            if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - offset) {
                callback();
            }
        };
        scrollContainer.addEventListener("scroll", handleScroll);
        return ()=>{
            scrollContainer.removeEventListener("scroll", handleScroll);
        };
    }, [
        container,
        offset
    ]);
}
export { useScrollToBottomAction };
