import { EExtendsMergeStrategy } from "./meta-data/meta-data.js";
/**
 * Retrieves the display name of a template. The function checks if the template
 * extends another template and, if so, optionally includes the extended template's
 * name in the display name. Additionally, it can include the template's identifier
 * in the display name.
 *
 * @param template The template metadata to process.
 * @param checkExtended Optional. If true, the function checks for an extended template. Defaults to true.
 * @param displayIdentifier Optional. If true, the template identifier is included in the display name. Defaults to true.
 * @returns The display name of the template.
 */ function getTemplateHeadline(template) {
    let checkExtended = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, displayIdentifier = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    let displayName = "";
    const subHeadline = template === null || template === void 0 ? void 0 : template.subHeadline;
    if (template) {
        let extendedName = undefined;
        const { headline } = template;
        if (checkExtended && template.extends && template.extendedMergeStrategies) {
            const nameStrategy = template.extendedMergeStrategies["headline"];
            if (nameStrategy === EExtendsMergeStrategy.Keep) {
                extendedName = template.extends.headline;
            }
        }
        if (extendedName) {
            if (headline) {
                displayName = `${extendedName} (${headline})`;
            } else {
                displayName = `${extendedName}${subHeadline ? ` (${subHeadline})` : ""}`;
            }
        } else {
            displayName = headline;
        }
        if (displayIdentifier) {
            displayName = `${displayName} [${template.identifier}]`;
        }
    }
    return displayName;
}
export { getTemplateHeadline };
