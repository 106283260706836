import { useQuery } from "@tanstack/react-query";
import { getReleaseDependencies } from "../api/real-cookie-banner.js";
import { useStores } from "../store/root.js";
import { QueryKeys } from "../util/query-keys.js";
function useReleaseDependenciesGet(customRef, params) {
    const { rcbAuthenticationStore } = useStores();
    const releaseQuery = useQuery(QueryKeys.getReleaseDependencies(customRef), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return getReleaseDependencies({
            signal,
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            params: params
        });
    }, {
        enabled: !!customRef,
        retry: false
    });
    return releaseQuery;
}
export { useReleaseDependenciesGet };
