import { routeLocationContentBlockerDelete, routeLocationContentBlockerFunctionLastEditorsGet, routeLocationContentBlockerFunctionToggleFavouritePut, routeLocationContentBlockerGet, routeLocationContentBlockerMetaGet, routeLocationContentBlockerPost, routeLocationContentBlockerPut, routeLocationContentBlockerStatisticsGet, routeLocationDependenciesGet, routeLocationEntityMetDataGet, routeLocationMediaDelete, routeLocationMediaGet, routeLocationPreReleaseClientPost, routeLocationPreReleaseClientPut, routeLocationPreReleaseClientsGet, routeLocationReleaseGet, routeLocationReleasePost, routeLocationReleasesGet, routeLocationServiceDelete, routeLocationServiceFunctionLastEditorsGet, routeLocationServiceFunctionToggleFavouritePut, routeLocationServiceGet, routeLocationServiceMetaGet, routeLocationServicePost, routeLocationServicePut, routeLocationServiceStatisticsGet, routeLocationTranslatePost, routeLocationUserSessionDelete, routeLocationUserSessionMagicLinkPost, routeLocationUserSessionPatch, routeLocationUserSessionPost } from "@devowl-wp/api-real-cookie-banner";
import { config } from "../config/config.js";
import { apiFetch } from "../util/api-fetch.js";
const apiRequest = apiFetch(config.environment.hosts.api.realCookieBanner, config.environment.hosts.api.realCookieBannerApiVersion);
async function getEntityMetaData(options) {
    const response = await apiRequest(routeLocationEntityMetDataGet, {
        ...options
    });
    return response;
}
async function getServiceTemplateBy(options) {
    const response = await apiRequest(routeLocationServiceGet, {
        ...options
    });
    return response;
}
async function updateServiceTemplate(options) {
    const response = await apiRequest(routeLocationServicePut, {
        ...options
    });
    return response;
}
async function createServiceTemplate(options) {
    const response = await apiRequest(routeLocationServicePost, {
        ...options
    });
    return response;
}
async function deleteServiceTemplate(options) {
    const response = await apiRequest(routeLocationServiceDelete, {
        ...options
    });
    return response;
}
async function toggleServiceTemplateFavourite(options) {
    const response = await apiRequest(routeLocationServiceFunctionToggleFavouritePut, {
        ...options
    });
    return response;
}
async function getServiceTemplateLastEditors(options) {
    const response = await apiRequest(routeLocationServiceFunctionLastEditorsGet, {
        ...options
    });
    return response;
}
async function getContentBlockerTemplateBy(options) {
    const response = await apiRequest(routeLocationContentBlockerGet, {
        ...options
    });
    return response;
}
async function updateContentBlockerTemplate(options) {
    const response = await apiRequest(routeLocationContentBlockerPut, {
        ...options
    });
    return response;
}
async function createContentBlockerTemplate(options) {
    const response = await apiRequest(routeLocationContentBlockerPost, {
        ...options
    });
    return response;
}
async function deleteContentBlockerTemplate(options) {
    const response = await apiRequest(routeLocationContentBlockerDelete, {
        ...options
    });
    return response;
}
async function deleteMedia(options) {
    const response = await apiRequest(routeLocationMediaDelete, {
        ...options
    });
    return response;
}
async function toggleContentBlockerTemplateFavourite(options) {
    const response = await apiRequest(routeLocationContentBlockerFunctionToggleFavouritePut, {
        ...options
    });
    return response;
}
async function getContentBlockerTemplateLastEditors(options) {
    const response = await apiRequest(routeLocationContentBlockerFunctionLastEditorsGet, {
        ...options
    });
    return response;
}
async function getContentBlockerTemplateMetaData(options) {
    const response = await apiRequest(routeLocationContentBlockerMetaGet, {
        ...options
    });
    return response;
}
async function getContentBlockerTemplateStatistics(options) {
    const response = await apiRequest(routeLocationContentBlockerStatisticsGet, {
        ...options
    });
    return response;
}
async function getServiceTemplateMetaData(options) {
    const response = await apiRequest(routeLocationServiceMetaGet, {
        ...options
    });
    return response;
}
async function getServiceTemplateStatistics(options) {
    const response = await apiRequest(routeLocationServiceStatisticsGet, {
        ...options
    });
    return response;
}
async function updatePreReleaseClient(options) {
    const response = await apiRequest(routeLocationPreReleaseClientPut, {
        ...options
    });
    return response;
}
async function postPreReleaseClient(options) {
    const response = await apiRequest(routeLocationPreReleaseClientPost, {
        ...options
    });
    return response;
}
async function getPreReleaseClients(options) {
    const response = await apiRequest(routeLocationPreReleaseClientsGet, {
        ...options
    });
    return response;
}
async function getRelease(options) {
    const response = await apiRequest(routeLocationReleaseGet, {
        ...options
    });
    return response;
}
async function getReleases(options) {
    const response = await apiRequest(routeLocationReleasesGet, {
        ...options
    });
    return response;
}
async function createRelease(options) {
    const response = await apiRequest(routeLocationReleasePost, {
        ...options
    });
    return response;
}
async function getReleaseDependencies(options) {
    const response = await apiRequest(routeLocationDependenciesGet, {
        ...options
    });
    return response;
}
async function getMedia(options) {
    const response = await apiRequest(routeLocationMediaGet, {
        ...options
    });
    return response;
}
function requestMagicLink(email, options) {
    return apiRequest(routeLocationUserSessionMagicLinkPost, {
        body: {
            user: {
                email
            }
        },
        ...options
    });
}
function requestSessionByMagicLink(magicLinkToken, options) {
    if (magicLinkToken) {
        return apiRequest(routeLocationUserSessionPost, {
            body: {
                userSession: {
                    type: "magicLink",
                    magicLinkToken
                }
            },
            ...options
        });
    }
    return undefined;
}
function requestSessionByPasswordLogin(email, password, options) {
    if (email && password) {
        return apiRequest(routeLocationUserSessionPost, {
            body: {
                userSession: {
                    type: "password",
                    email,
                    password
                }
            },
            ...options
        });
    }
    return undefined;
}
function requestSessionDelete(session, options) {
    return apiRequest(routeLocationUserSessionDelete, {
        headers: {
            Authorization: `Bearer ${session === null || session === void 0 ? void 0 : session.jwt}`
        },
        ...options
    });
}
function requestSessionRenew(session, options) {
    return apiRequest(routeLocationUserSessionPatch, {
        headers: {
            Authorization: `Bearer ${session === null || session === void 0 ? void 0 : session.jwt}`
        },
        ...options
    });
}
function getTranslation(options) {
    return apiRequest(routeLocationTranslatePost, {
        ...options
    });
}
export { createContentBlockerTemplate, createRelease, createServiceTemplate, deleteContentBlockerTemplate, deleteMedia, deleteServiceTemplate, getContentBlockerTemplateBy, getContentBlockerTemplateLastEditors, getContentBlockerTemplateMetaData, getContentBlockerTemplateStatistics, getEntityMetaData, getMedia, getPreReleaseClients, getRelease, getReleaseDependencies, getReleases, getServiceTemplateBy, getServiceTemplateLastEditors, getServiceTemplateMetaData, getServiceTemplateStatistics, getTranslation, postPreReleaseClient, requestMagicLink, requestSessionByMagicLink, requestSessionByPasswordLogin, requestSessionDelete, requestSessionRenew, toggleContentBlockerTemplateFavourite, toggleServiceTemplateFavourite, updateContentBlockerTemplate, updatePreReleaseClient, updateServiceTemplate };
