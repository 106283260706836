import { makeAutoObservable, runInAction } from "mobx";
import { getUserRoleHierarchy } from "@devowl-wp/api-real-cookie-banner";
import { requestSessionDelete, requestSessionRenew } from "../../api/real-cookie-banner.js";
import { ConsoleLogger } from "../../others/console-logger.js";
import { ESessionState } from "../../types/store/authentication.js";
import { localStorageTest } from "../../util/localStorage.js";
const LOCAL_STORAGE_RCB_SESSION_KEY = "rcb-session";
const SESSION_MINIMUM_LEFT = 1000 * 60 * 60 * 24 * 7; // 7 days
const SESSION_RENEW_CHECK_INTERVAL = 1000 * 60 * 5; // 5 minutes
/**
 * Handle Real Cookie Banner backend authentication.
 * Session handling made easy.
 */ class RcbAuthenticationStore {
    /** Default to get access to other stores. */ rootStore;
    sessionState;
    log = new ConsoleLogger(this);
    constructor(rootStore){
        this.rootStore = rootStore;
        this.sessionState = ESessionState.Initial;
        this.restoreSessionStateFromLocalStorage();
        makeAutoObservable(this, {
            rootStore: false
        }, {
            autoBind: true
        });
        setInterval(()=>{
            runInAction(async ()=>{
                await this.sessionRenew();
            });
        }, SESSION_RENEW_CHECK_INTERVAL);
    }
    get session() {
        return this.getSessionFromLocalStorage();
    }
    async sessionLogout(onSuccess) {
        this.log.dev(`Logout: ${new Date().toISOString()}`);
        try {
            const storedSession = this.session;
            if (this.sessionState === ESessionState.Stored || storedSession) {
                await requestSessionDelete(storedSession);
            }
            this.sessionDestroy();
            if (onSuccess) {
                onSuccess();
            }
        } catch (error) {
            // No error logging as defined at "Sign out" https://app.clickup.com/2088/v/dc/218-357/218-7781
            this.sessionState = ESessionState.None;
        }
    }
    sessionDestroy() {
        this.log.dev(`Destroy session: ${new Date().toISOString()}`);
        this.writeSessionToLocalStorage(undefined);
    }
    hasRole(roleToCheck) {
        var _this_session_user, _this_session, _getUserRoleHierarchy_currentRole;
        const currentRole = (_this_session = this.session) === null || _this_session === void 0 ? void 0 : (_this_session_user = _this_session.user) === null || _this_session_user === void 0 ? void 0 : _this_session_user.role;
        if (!currentRole) {
            return false;
        }
        return !!(((_getUserRoleHierarchy_currentRole = getUserRoleHierarchy()[currentRole]) === null || _getUserRoleHierarchy_currentRole === void 0 ? void 0 : _getUserRoleHierarchy_currentRole.indexOf(roleToCheck)) > -1);
    }
    async sessionRenew() {
        const localSession = this.getSessionFromLocalStorage();
        if (this.shouldSessionRenew(localSession)) {
            this.log.dev(`Request session renewal: ${new Date().toISOString()}`);
            const sessionRenewed = await requestSessionRenew(localSession);
            this.writeSessionToLocalStorage({
                jwt: sessionRenewed.jwt,
                validUntil: sessionRenewed.validUntil,
                user: sessionRenewed.user
            });
        }
    }
    shouldSessionRenew(session) {
        if (session === null || session === void 0 ? void 0 : session.validUntil) {
            var _session_validUntil;
            const diff = (session === null || session === void 0 ? void 0 : (_session_validUntil = session.validUntil) === null || _session_validUntil === void 0 ? void 0 : _session_validUntil.getTime()) - Date.now();
            const isSessionMinimumTimeReached = diff <= SESSION_MINIMUM_LEFT;
            this.log.dev(`Should session renew by: IsSessionMinimumTimeReached->${isSessionMinimumTimeReached ? "yes" : "no"}`);
            return isSessionMinimumTimeReached;
        } else {
            return false;
        }
    }
    writeSessionToLocalStorage(session) {
        if (localStorageTest()) {
            this.log.dev(`LocalStorage write session ${session ? JSON.stringify(session) : session}`);
            const sessionToStore = session ? JSON.stringify(session) : null;
            if (sessionToStore) {
                localStorage.setItem(LOCAL_STORAGE_RCB_SESSION_KEY, sessionToStore);
                this.sessionState = ESessionState.Stored;
            } else {
                localStorage.removeItem(LOCAL_STORAGE_RCB_SESSION_KEY);
                this.sessionState = ESessionState.None;
            }
        }
    }
    getSessionFromLocalStorage() {
        this.log.dev(`LocalStorage read session.`);
        const sessionStr = localStorageTest() ? localStorage.getItem(LOCAL_STORAGE_RCB_SESSION_KEY) : null;
        const session = sessionStr ? JSON.parse(sessionStr) : null;
        if (session === null || session === void 0 ? void 0 : session.validUntil) {
            session.validUntil = new Date(session.validUntil);
        }
        return session;
    }
    restoreSessionStateFromLocalStorage() {
        const storedSession = this.session;
        this.log.dev(`LocalStorage restore session: ${storedSession ? "OK" : "N/A"}.`);
        this.sessionState = storedSession ? ESessionState.Stored : ESessionState.None;
    }
}
export { RcbAuthenticationStore };
