import { useQuery } from "@tanstack/react-query";
import { deleteServiceTemplate } from "../../api/real-cookie-banner.js";
import { useStores } from "../../store/root.js";
import { QueryKeys } from "../../util/query-keys.js";
/**
 * Update given serviceTemplate via RCB Service API.
 */ function useServiceTemplateDelete(id) {
    var _String;
    const { rcbAuthenticationStore } = useStores();
    return useQuery(QueryKeys.deleteServiceTemplate(id), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return deleteServiceTemplate({
            signal,
            language: "en",
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            params: {
                id: String(id)
            }
        });
    }, {
        staleTime: Infinity,
        retry: false,
        enabled: ((_String = String(id)) === null || _String === void 0 ? void 0 : _String.trim().length) > 0 && !isNaN(Number(id))
    });
}
export { useServiceTemplateDelete };
