import { useQuery } from "@tanstack/react-query";
import { createContentBlockerTemplate } from "../../api/real-cookie-banner.js";
import { useStores } from "../../store/root.js";
import { QueryKeys } from "../../util/query-keys.js";
/**
 * Create given content blocker template via RCB Service API.
 */ function useContentBlockerTemplateCreate(contentBlockerTemplate) {
    const { rcbAuthenticationStore } = useStores();
    return useQuery(QueryKeys.createContentBlocker(contentBlockerTemplate), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return createContentBlockerTemplate({
            signal,
            language: "en",
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            body: {
                contentBlockerTemplate
            }
        });
    }, {
        staleTime: Infinity,
        enabled: !!contentBlockerTemplate
    });
}
export { useContentBlockerTemplateCreate };
