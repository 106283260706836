var EEnvironmentName;
(function(EEnvironmentName) {
    EEnvironmentName["Development"] = "development";
    EEnvironmentName["Staging"] = "staging";
    EEnvironmentName["Production"] = "production";
})(EEnvironmentName || (EEnvironmentName = {}));
var EEnvironmentProtocol;
(function(EEnvironmentProtocol) {
    EEnvironmentProtocol["http"] = "http";
    EEnvironmentProtocol["https"] = "https";
})(EEnvironmentProtocol || (EEnvironmentProtocol = {}));
export { EEnvironmentName, EEnvironmentProtocol };
