import { ETranslationFlag } from "@devowl-wp/api";
import { DEFAULT_LANGUAGE } from "@devowl-wp/api-real-cookie-banner";
/**
 * This function prepares statistics table-data for our supported languages.
 * It takes an array of supported languages and an array of statistics entities as inputs.
 */ function prepareStatisticsData(languages, statistics) {
    const sumFlaggedWords = (flag, language)=>statistics.map((statistic)=>{
            var _statistic_translations_language;
            return ((_statistic_translations_language = statistic.translations[language]) === null || _statistic_translations_language === void 0 ? void 0 : _statistic_translations_language.sumTranslationFlaggedWords[flag]) || 0;
        }).reduce((prev, curr)=>prev + curr, 0);
    // Initialize an array to hold the processed language and statistics data.
    const processedData = [
        {
            // For the default language, calculate translatable and translated words.
            // Spread the properties of the default language option into this object.
            ...languages.find((langOption)=>langOption.language === DEFAULT_LANGUAGE),
            translatable: statistics.map((stat)=>stat.sumTranslatableWords).reduce((prev, current)=>current + prev),
            translated: statistics.map((stat)=>stat.sumTranslatableWords).reduce((prev, current)=>current + prev),
            notTranslated: 0,
            fuzzy: 0,
            machineTranslated: 0,
            statusInPercent: 100,
            calculatedIdentifier: {
                serviceIdentifier: [],
                contentBlockerIdentifier: []
            }
        },
        // Process each supported language that is not the default language.
        ...languages.filter((langOption)=>langOption.language !== DEFAULT_LANGUAGE).map((langOption)=>{
            // Calculate translated and translatable words for the current language.
            const translatedWords = statistics.map((statistic)=>{
                var _statistic_translations_langOption_language;
                return ((_statistic_translations_langOption_language = statistic.translations[langOption.language]) === null || _statistic_translations_langOption_language === void 0 ? void 0 : _statistic_translations_langOption_language.sumTranslatedWords) || 0;
            }).reduce((prev, curr)=>prev + curr);
            const sumTranslatableWords = statistics.map((stat)=>stat.sumTranslatableWords).reduce((prev, current)=>current + prev);
            // Calculate the number of words not translated for the current language.
            const notTranslated = sumTranslatableWords - translatedWords;
            // Initialize an object to hold missing translation identifiers.
            const calculatedIdentifier = {
                contentBlockerIdentifier: [],
                serviceIdentifier: []
            };
            // Iterate through statistics to gather missing translation identifiers.
            statistics.map((stat)=>{
                // Categorize missing identifiers based on entity type.
                if (stat.entity === "ContentBlockerTemplate") {
                    calculatedIdentifier.contentBlockerIdentifier.push(...stat.calculatedIdentifier);
                } else if (stat.entity === "ServiceTemplate") {
                    calculatedIdentifier.serviceIdentifier.push(...stat.calculatedIdentifier);
                }
            });
            const fuzzy = sumFlaggedWords(ETranslationFlag.Fuzzy, langOption.language);
            const machineTranslated = sumFlaggedWords(ETranslationFlag.MachineTranslated, langOption.language);
            // Create an object for the current language option with calculated values.
            return {
                ...langOption,
                translatable: sumTranslatableWords,
                translated: translatedWords,
                notTranslated,
                fuzzy,
                machineTranslated,
                statusInPercent: translatedWords + sumTranslatableWords > 0 ? (translatedWords / sumTranslatableWords * 100).toFixed(2) : 100,
                calculatedIdentifier
            };
        })
    ];
    // Return the processed data containing language and statistics information.
    return processedData;
}
export { prepareStatisticsData };
