import { useEffect } from "react";
import { mapValidationErrorToFormDataError } from "../../util/template/error.js";
function handleResetFieldData(form) {
    let errorReset = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, warningReset = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    const errors = form.getFieldsError(); // Gets an object containing the validation errors for all fields
    const resetErrors = errors.map((error)=>{
        var _error_errors, _error_warnings;
        if (((_error_errors = error.errors) === null || _error_errors === void 0 ? void 0 : _error_errors.length) > 0 || ((_error_warnings = error.warnings) === null || _error_warnings === void 0 ? void 0 : _error_warnings.length) > 0) {
            var _fieldData_errors, _fieldData_warnings;
            const fieldData = {
                name: error.name,
                errors: error.errors,
                warnings: error.warnings
            };
            if (errorReset === true && ((_fieldData_errors = fieldData.errors) === null || _fieldData_errors === void 0 ? void 0 : _fieldData_errors.length) > 0) {
                fieldData.errors = [];
            }
            if (warningReset === true && ((_fieldData_warnings = fieldData.warnings) === null || _fieldData_warnings === void 0 ? void 0 : _fieldData_warnings.length) > 0) {
                fieldData.warnings = [];
            }
            return fieldData;
        }
        return undefined;
    }).filter(Boolean);
    if ((resetErrors === null || resetErrors === void 0 ? void 0 : resetErrors.length) > 0) {
        form.setFields(resetErrors);
    }
}
function useFormValidation(form, errors) {
    useEffect(()=>{
        if (!form) {
            return;
        }
        handleResetFieldData(form);
        if (errors && errors.length > 0) {
            // in an error case, we need to map errors to field data and set it
            const errorFields = mapValidationErrorToFormDataError(errors);
            form.setFields(errorFields);
            // try to scroll to error, bottom up (last matching wins)
            for(let i = errorFields.length - 1; i >= 0; i--){
                const errorField = errorFields[i];
                form.scrollToField(errorField.name);
            }
        }
    }, [
        form,
        errors
    ]);
}
export { useFormValidation };
