import { useQuery } from "@tanstack/react-query";
import { getContentBlockerTemplateMetaData } from "../../api/real-cookie-banner.js";
import { useStores } from "../../store/root.js";
import { QueryKeys } from "../../util/query-keys.js";
function useContentBlockerTemplateMetaData() {
    let isEnabled = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true, refetchOnMount = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, filterIdentifiers = arguments.length > 2 ? arguments[2] : void 0, includeDeleted = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false;
    const { rcbAuthenticationStore } = useStores();
    const templateMetaQuey = useQuery(QueryKeys.getContentBlockerMeta(filterIdentifiers, includeDeleted), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return getContentBlockerTemplateMetaData({
            signal,
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            params: {
                filterIdentifiers: (filterIdentifiers === null || filterIdentifiers === void 0 ? void 0 : filterIdentifiers.join(",")) || "",
                includeDeleted
            }
        });
    }, {
        enabled: isEnabled,
        refetchOnMount
    });
    return templateMetaQuey;
}
export { useContentBlockerTemplateMetaData };
