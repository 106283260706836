import { useQuery } from "@tanstack/react-query";
import { requestSessionByMagicLink } from "../api/real-cookie-banner.js";
import { useStores } from "../store/root.js";
import { QueryKeys } from "../util/query-keys.js";
/**
 * Query a session via magic link token.
 */ function useMagicLinkSessionRequest(magicLinkToken) {
    const { rcbAuthenticationStore } = useStores();
    return useQuery(QueryKeys.createMagicLinkSessionByToken(magicLinkToken), async (param)=>{
        let { signal } = param;
        await rcbAuthenticationStore.sessionLogout(); // invalidate old session - if possible
        return requestSessionByMagicLink(magicLinkToken, {
            signal,
            language: "en"
        });
    }, {
        staleTime: Infinity,
        retry: false,
        enabled: !!magicLinkToken,
        onSuccess: (sessionResponse)=>{
            rcbAuthenticationStore.writeSessionToLocalStorage(sessionResponse);
        }
    });
}
export { useMagicLinkSessionRequest };
