import { useQuery } from "@tanstack/react-query";
import { getReleases } from "../api/real-cookie-banner.js";
import { useStores } from "../store/root.js";
import { QueryKeys } from "../util/query-keys.js";
function useReleases() {
    const { rcbAuthenticationStore } = useStores();
    const releasesQuery = useQuery(QueryKeys.getReleases(), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return getReleases({
            signal,
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            }
        });
    });
    return releasesQuery;
}
export { useReleases };
