import nginx from "nginx";
import { EEnvironmentName, EEnvironmentProtocol } from "@devowl-wp/react-frontend";
export const environment = {
    name: EEnvironmentName.Development,
    protocol: EEnvironmentProtocol.http,
    hosts: {
        api: {
            realCookieBanner: `https://rcb.${nginx.env.ENVIRONMENT_BASE_HOSTNAME}`,
            realCookieBannerApiVersion: "1.0.0"
        },
        web: `https://admin.${nginx.env.ENVIRONMENT_BASE_HOSTNAME}`
    }
};
