import { useQuery } from "@tanstack/react-query";
import { getEntityMetaData } from "../api/real-cookie-banner.js";
import { useStores } from "../store/root.js";
import { QueryKeys } from "../util/query-keys.js";
/**
 * Hook to fetch metadata for a specific entity.
 * @param entity The name of the entity to fetch metadata for.
 * @returns A query object containing the entity metadata and loading state.
 */ function useEntityMetadata(entity) {
    const { rcbAuthenticationStore } = useStores();
    const entityMetadataQuery = useQuery(QueryKeys.getEntityMetadata(entity), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return getEntityMetaData({
            signal,
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            params: {
                entity
            }
        });
    }, {
        staleTime: 1000 * 60 * 60 * 24,
        enabled: !!entity
    });
    return entityMetadataQuery;
}
export { useEntityMetadata };
