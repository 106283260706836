import { useQuery } from "@tanstack/react-query";
import { requestMagicLink } from "../api/real-cookie-banner.js";
import { QueryKeys } from "../util/query-keys.js";
/**
 * Request a magic link email.
 */ function useMagicLinkRequest(email) {
    return useQuery(QueryKeys.createMagicLinkRequest(email), (param)=>{
        let { signal } = param;
        return requestMagicLink(email, {
            signal,
            language: "en"
        });
    }, {
        staleTime: Infinity,
        retry: false,
        enabled: !!email
    });
}
export { useMagicLinkRequest };
