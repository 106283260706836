import { config } from "../config/config.js";
/**
 * Logger instance for console logs in react frontend environment.
 * If a console.log is made will be chosen via actual environment.
 */ class ConsoleLogger {
    contextInstance;
    /**
     * Give context to your logger - instance of a class.
     */ constructor(contextInstance){
        this.contextInstance = contextInstance;
    }
    /**
     * Development logging function, as context use "this".
     */ dev(message) {
        for(var _len = arguments.length, optionalParams = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
            optionalParams[_key - 1] = arguments[_key];
        }
        if (config.environment.name === "development") {
            this.log(message, ...optionalParams);
        }
    }
    /**
     * Staging logging function, as context use "this".
     */ staging(message) {
        for(var _len = arguments.length, optionalParams = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
            optionalParams[_key - 1] = arguments[_key];
        }
        if (config.environment.name === "staging") {
            this.log(message, ...optionalParams);
        }
    }
    /**
     * Error logging function, as context use "this".
     * Will log on any environment.
     */ error(message) {
        for(var _len = arguments.length, optionalParams = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
            optionalParams[_key - 1] = arguments[_key];
        }
        this.log(`ERROR ${message}`, ...optionalParams);
    }
    /**
     * Will log on any environment, as context use "this".
     */ log(message) {
        for(var _len = arguments.length, optionalParams = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
            optionalParams[_key - 1] = arguments[_key];
        }
        if ((optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) > 0) {
            console.log(`${new Date().toISOString()}[${this.contextInstance.constructor.name}] ${message}`, ...optionalParams);
        } else {
            console.log(`${new Date().toISOString()}[${this.contextInstance.constructor.name}] ${message}`);
        }
    }
}
export { ConsoleLogger };
