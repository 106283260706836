import { DEFAULT_LANGUAGE } from "../../route/supported-languages/supported-languages.get.js";
var EUserRole;
(function(EUserRole) {
    EUserRole["Admin"] = "admin";
    EUserRole["Creator"] = "creator";
    EUserRole["Translator"] = "translator";
    EUserRole["Client"] = "client";
})(EUserRole || (EUserRole = {}));
const roleHierarchy = {
    ["admin"]: [
        "admin",
        "creator",
        "translator"
    ],
    ["creator"]: [
        "creator",
        "translator"
    ],
    ["translator"]: [
        "translator"
    ],
    ["client"]: [
        "client"
    ]
};
Object.freeze(roleHierarchy);
/**
 * Returns user role hierarchy definition.
 *
 * Use Case:
 * You are a Creator and want to translate something (=Role Translator)
 *
 * Example:
 * Use this function, and get hierarchy for Creator.
 * Next check if Creator has a mapping to the role Translator
 *
 * Result:
 * Creator has also the Role Translator.
 */ function getUserRoleHierarchy() {
    return roleHierarchy;
}
/**
 * Retrieves the list of allowed languages for a user based on their role.
 * If the user is an Admin, it signifies no restrictions by returning undefined.
 * Otherwise, it returns the allowed languages if specified, or an empty array
 * indicating no specific allowances.
 *
 * @param user An object representing the user, including their role and role parameters.
 * @returns An array of allowed language codes or undefined if no restrictions apply.
 */ function getAllowedLanguages(user) {
    var _user_roleParameters;
    // If the user is an Admin, immediately return undefined, signifying no restrictions.
    if ((user === null || user === void 0 ? void 0 : user.role) === "admin") {
        return undefined;
    }
    // Return allowedLanguages if they exist; otherwise, return empty => nothing allowed, but "en".
    return (user === null || user === void 0 ? void 0 : (_user_roleParameters = user.roleParameters) === null || _user_roleParameters === void 0 ? void 0 : _user_roleParameters.allowedLanguages) || [];
}
/**
 * Determines if a specific language is allowed for a user based on their role
 * and allowed languages. The default language is always considered allowed.
 *
 * @param user An object representing the user, including their role and role parameters.
 * @param language A language code to check against the user's allowed languages.
 * @returns A boolean indicating whether the language is allowed for the user.
 */ function isLanguageAllowed(user, language) {
    const allowed = getAllowedLanguages(user);
    if (allowed === undefined || language === DEFAULT_LANGUAGE) {
        return true;
    } else {
        const set = new Set(allowed ? allowed.map((lang)=>lang.toString()) : []);
        return set.has(language);
    }
}
export { EUserRole, getUserRoleHierarchy, getAllowedLanguages, isLanguageAllowed };
