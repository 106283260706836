import { action, makeAutoObservable, runInAction } from "mobx";
import { DEFAULT_LANGUAGE, ESupportedLanguage, EUserRole, getAllowedLanguages } from "@devowl-wp/api-real-cookie-banner";
import { ConsoleLogger } from "../../others/console-logger.js";
const LOCAL_STORAGE_RCB_USER_SETTINGS_KEY = "rcb-user-settings";
const DEFAULT_TRANSLATION_SETTINGS = {
    isStickyMode: true,
    sourceLanguage: DEFAULT_LANGUAGE,
    targetLanguage: ESupportedLanguage.de_formal,
    isTranslationMode: false
};
Object.freeze(DEFAULT_TRANSLATION_SETTINGS);
/**
 * Handle Real Cookie Banner user settings.
 */ class RcbUserSettingsStore {
    /** Default to get access to other stores. */ rootStore;
    settings;
    log = new ConsoleLogger(this);
    constructor(rootStore){
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            rootStore: false,
            updateSettings: action
        }, {
            autoBind: true
        });
        runInAction(()=>{
            var _this_rootStore_rcbAuthenticationStore_session, _this_rootStore_rcbAuthenticationStore;
            this.settings = this.loadSettings((_this_rootStore_rcbAuthenticationStore = this.rootStore.rcbAuthenticationStore) === null || _this_rootStore_rcbAuthenticationStore === void 0 ? void 0 : (_this_rootStore_rcbAuthenticationStore_session = _this_rootStore_rcbAuthenticationStore.session) === null || _this_rootStore_rcbAuthenticationStore_session === void 0 ? void 0 : _this_rootStore_rcbAuthenticationStore_session.user);
        });
    }
    loadSettings(user) {
        const storedSettings = localStorage.getItem(LOCAL_STORAGE_RCB_USER_SETTINGS_KEY);
        const loadedOrDefault = storedSettings ? JSON.parse(storedSettings) : {
            translationSettings: this.getDefaultTranslationSettingsFor(user)
        };
        // Remove unknown settings from translationSettings
        if (loadedOrDefault.translationSettings) {
            const knownSettings = Object.keys(DEFAULT_TRANSLATION_SETTINGS);
            for(const key in loadedOrDefault.translationSettings){
                if (!knownSettings.includes(key)) {
                    delete loadedOrDefault.translationSettings[key];
                }
            }
        }
        this.log.dev(`LocalStorage loaded user settings.`, {
            settings: storedSettings,
            loadedOrDefault
        });
        return loadedOrDefault;
    }
    saveSettings() {
        this.log.dev(`LocalStorage  settings ${this.settings ? JSON.stringify(this.settings) : this.settings}`);
        localStorage.setItem(LOCAL_STORAGE_RCB_USER_SETTINGS_KEY, JSON.stringify(this.settings));
    }
    updateSettings(settings) {
        this.settings = {
            ...this.settings,
            ...settings
        };
        this.log.dev(`LocalStorage updated user settings:`, {
            userSettings: JSON.parse(JSON.stringify(this.settings || "undefined"))
        });
        this.saveSettings();
    }
    updateTranslationSettings(settings) {
        Object.assign(this.settings.translationSettings, settings);
        this.saveSettings();
    }
    /**
     * Gets the default translation settings for a given user. The settings are determined
     * based on the user's role.
     *
     * If the user is a Translator, the function checks for the
     * allowed languages and sets the target language to the first allowed language, defaulting
     * to German (formal) if no allowed languages are found or the list is empty.
     *
     * For other roles
     * such as Admin, Creator, or Client, it returns the default translation settings.
     *
     * @param user The user object for whom the default translation settings are to be determined.
     *             The user object must contain at least the 'role' property and optionally
     *             the 'allowedLanguages' property if the user is a Translator.
     * @returns An object containing the translation settings.
     */ getDefaultTranslationSettingsFor(user) {
        const langs = getAllowedLanguages(user);
        let defaultTarget = ESupportedLanguage.de_formal;
        if ((langs === null || langs === void 0 ? void 0 : langs.length) > 0) {
            defaultTarget = ESupportedLanguage[langs[0].toString()];
        }
        switch(user === null || user === void 0 ? void 0 : user.role){
            case EUserRole.Translator:
                return {
                    isStickyMode: true,
                    sourceLanguage: DEFAULT_LANGUAGE,
                    targetLanguage: defaultTarget,
                    isTranslationMode: true
                };
            default:
                // for EUserRole.Admin, EUserRole.Creator, EUserRole.Client
                return DEFAULT_TRANSLATION_SETTINGS;
        }
    }
}
export { RcbUserSettingsStore };
